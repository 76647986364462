import { render, staticRenderFns } from "./watermarkConfiguration-edit.vue?vue&type=template&id=4e44ee57&scoped=true&"
import script from "./watermarkConfiguration-edit.vue?vue&type=script&lang=js&"
export * from "./watermarkConfiguration-edit.vue?vue&type=script&lang=js&"
import style0 from "./watermarkConfiguration-edit.vue?vue&type=style&index=0&id=4e44ee57&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e44ee57",
  null
  
)

export default component.exports